.start-page {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.data-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    font-size: 20px;
    color: #007bff;
}

.edit-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
}

.edit-button:hover {
    text-decoration: underline;
}

.edit-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.save-button {
    background: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.save-button:hover {
    background: #218838;
}
