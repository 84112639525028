/* CompaniesList.css */

/* Kontener tabeli */
.table-container {
  padding: 20px;
  margin: 0 auto;
  width: 100%; /* Rozciąga się na całą szerokość strony */
  background-color: #f8f9fa; /* Jasnoszare tło strony */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Delikatny cień dla kontrastu */
}

/* Stylizacja tabeli */
table {
  width: 100%; /* Rozciąga tabelę na całą szerokość kontenera */
  border-collapse: collapse; /* Usuwa odstępy między komórkami */
  background-color: #fff; /* Białe tło tabeli */
  border: 1px solid #ddd; /* Cienka ramka wokół tabeli */
}

/* Stylizacja nagłówków tabeli */
thead {
  background-color: #eaeaea; /* Jasnoszary kolor tła nagłówków */
  color: #6c757d; /* Lekko szary kolor tekstu */
}

thead th {
  padding: 15px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #ccc; /* Linia pod nagłówkami */
}

/* Stylizacja wierszy w tabeli */
tbody tr {
  border-bottom: 1px solid #ddd; /* Linia między wierszami */
  transition: background-color 0.2s ease-in-out; /* Animacja podświetlenia */
}

tbody tr:hover {
  background-color: #f1f1f1; /* Lekko szare podświetlenie wiersza */
}

/* Stylizacja komórek tabeli */
tbody td {
  padding: 12px;
  text-align: left;
  font-size: 14px;
}

/* Stylizacja przycisków */
tbody td button {
  padding: 6px 12px;
  background-color: #28a745; /* Zielony kolor przycisków */
  color: #fff; /* Biały tekst na przyciskach */
  border: none;
  border-radius: 4px; /* Zaokrąglone rogi przycisków */
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;
}

tbody td button:hover {
  background-color: #218838; /* Ciemniejszy zielony kolor przy najechaniu */
}

/* Stylizacja pierwszej kolumny (ikony statusu) */
tbody td:first-child {
  text-align: center;
  font-size: 16px;
}

/* Komunikat błędu */
.error-message {
  color: red;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.records-per-page {
  display: flex;
  align-items: center;
}

.records-per-page label {
  margin-right: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.status-true {
  color: green;
  font-size: 18px;
  font-weight: bold;
}

.status-false {
  color: red;
  font-size: 18px;
  font-weight: bold;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.active-link {
  color: darkblue;
  font-weight: bold;
}



