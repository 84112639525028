.sticky-menu {
    position: fixed; /* Przyklej do góry */
    top: 0;
    left: 0;
    width: 100%; /* Na całą szerokość */
    background-color: #ffffff;
    z-index: 1050;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0;
}

.container-fluid {
    padding: 0; /* Usuń marginesy */
}

.navbar-collapse {
    flex-grow: 1; /* Rozciągnij na całą szerokość */
}

.menu-item {
    font-size: 1rem;
    font-weight: 500;
    color: #007bff;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.menu-item:hover {
    color: #0056b3;
    text-decoration: none;
}

.form-control {
    width: 300px;
    border-radius: 5px;
}

.navbar-text {
    font-size: 0.9rem;
    font-weight: bold;
    color: #333333;
}
.dropdown-item {
    text-decoration: none !important;
    color: inherit;
}

