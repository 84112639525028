.homepage-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.data-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.data-section {
    flex: 1;
}

.data-section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.data-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 1rem;
}

.data-status {
    color: #6c757d;
}

.data-highlight {
    color: #ff4500;
    font-weight: bold;
}

.circle-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border: 2px solid #ddd;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: #333;
    background-color: #fff;
}

.tile-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.tile-link {
    text-decoration: none;
    color: inherit;
}

.tile {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: transform 0.2s;
}

.tile:hover {
    transform: scale(1.05);
}

.tile h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
}

.tile p {
    font-size: 0.9rem;
    color: #666;
}
