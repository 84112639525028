.add-mitarbeiter-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.section-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    color: #333;
}

.form-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.form-row {
    flex: 1 1 calc(50% - 20px);
    display: flex;
    flex-direction: column;
}

.form-label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.form-input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.2s ease-in-out;
}

.form-input:focus {
    border-color: #007bff;
    outline: none;
}

.form-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.form-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.form-button:hover {
    background-color: #0056b3;
}
