/* Ogólny styl formularza */
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.login-form h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.login-form .form-group {
    margin-bottom: 15px;
    width: 100%;
    max-width: 300px;
}

.login-form .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.login-form .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-form button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-form button:hover {
    background-color: #0056b3;
}

/* Styl błędu */
.login-form .error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
}
