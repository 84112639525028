.company-details {
    width: 100%; /* Zajmij całą szerokość ekranu */
    margin: 0 auto; /* Wycentruj zawartość */
    padding: 20px; /* Dodaj odstępy wewnętrzne */
    background: #ffffff;
}

.menu {
    display: flex; /* Ustawienie menu w jednej linii */
    justify-content: flex-start; /* Wyrównanie do lewej strony */
    align-items: center; /* Wyrównanie elementów w pionie */
    background-color: #f8f9fa; /* Tło menu */
    padding: 15px 20px; /* Dodanie odstępu wewnętrznego */
    border-bottom: 1px solid #ddd; /* Dolne obramowanie */
    gap: 30px; /* Odstępy między elementami menu */
}

.menu ul {
    display: flex; /* Ustawienie elementów listy w jednej linii */
    list-style: none; /* Usunięcie kropek listy */
    padding: 0; /* Usunięcie odstępów wewnętrznych */
    margin: 0; /* Usunięcie odstępów zewnętrznych */
    gap: 30px; /* Odstępy między elementami */
}

.menu ul li {
    font-size: 14px; /* Rozmiar tekstu */
    font-weight: bold; /* Pogrubienie tekstu */
    cursor: pointer; /* Wskaźnik kursora */
    color: #333; /* Kolor tekstu */
    padding: 5px 10px; /* Odstęp wewnętrzny */
    transition: background-color 0.3s, color 0.3s; /* Efekt hover */
}

.menu ul li:hover {
    color: #007bff; /* Kolor podczas najechania */
    background-color: #e9ecef; /* Tło podczas najechania */
    border-radius: 5px; /* Zaokrąglenie krawędzi */
}




.header {
    text-align: left;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 32px; /* Powiększ tekst nagłówka */
    font-weight: bold;
    color: #333;
}

.status-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 100%;
}

.status-box {
    flex: 1;
    margin-top: 20px;
    margin: 10px;
    padding: 20px;
    background: #eafaf1;
    border: 1px solid #d4edda;
    text-align: center;
    font-size: 16px; /* Zwiększ rozmiar czcionki */
    font-weight: bold;
    color: #155724;
}


.status-active {
    background: #eafaf1; /* Jasnozielone tło */
    border: 1px solid #d4edda; /* Zielona ramka */
    color: #155724; /* Zielony tekst */
}

.status-inactive {
    background: #fdecea; /* Jasnoczerwone tło */
    border: 1px solid #f5c6cb; /* Czerwona ramka */
    color: #721c24; /* Czerwony tekst */
}


.info-section {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    background: #f8f9fa;
}

.info-section-2 {
    width: 30%;       /* Setzt die Breite auf 50% der übergeordneten Breite */
    margin-left: 0px;     /* Zentriert das Element horizontal */
    padding: 10px;    /* Fügt ein Padding hinzu, um etwas Raum um den Inhalt zu schaffen */
   /* box-shadow: 0 4px 8px rgba(0,0,0,0.1);   Fügt einen leichten Schatten hinzu */
    /* background-color: #ffffff;   Setzt eine Hintergrundfarbe */
}


.info-section h2 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.info-columns {
    display: flex; /* Ustawienie flexboxa */
    justify-content: space-between; /* Rozdzielenie kolumn */
}

.column {
    flex: 1; /* Każda kolumna zajmuje równo 50% szerokości */
    margin: 0 10px; /* Odstępy między kolumnami */
}

.column p {
    margin: 10px 0;
    font-size: 16px;
}

.data-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Aby dostosować się do mniejszych ekranów */
}

.data-row p {
    margin: 10px 0;
    font-size: 16px;
    flex: 1 0 30%; /* Każdy element zajmuje 30% szerokości */
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    font-size: 16px;
}

table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #333;
}
.info-columns-2 {
    display: flex; /* Ustawienie kolumn obok siebie */
    justify-content: space-between; /* Rozdzielenie kolumn */
    background: rgba(255, 255, 255, 0.5); /* Przezroczystość */
    padding: 20px;
    border-radius: 10px; /* Zaokrąglenie rogów */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Lekki cień */
}
.column {
    flex: 1;
    margin: 0 10px; /* Odstępy między kolumnami */
    padding: 15px; /* Wewnętrzny margines */
    border-radius: 8px; /* Zaokrąglenie rogów */
    color: #333;
    font-size: 16px;
}

.column-stammdaten {
    background-color: #eafaf1; /* Zielone tło */
    border: 1px solid #d4edda; /* Zielona ramka */
}

.column-ansprechpartner {
    background-color: #f9f9f9; /* Szare tło */
    border: 1px solid #ddd; /* Szara ramka */
}

.column-versicherung {
    background-color: #ffe4e1; /* Różowe tło */
    border: 1px solid #f5c6cb; /* Czerwona ramka */
}
